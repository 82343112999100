<template>
    <v-card-content>
        <user-edit-security-form v-if="user" :user="user" @user-updated="$emit('user-updated')" />
    </v-card-content>
</template>

<script>
import UserEditSecurityForm from "@/components/application/user/user-edit-security-form.vue";

export default {
    components: {
        userEditSecurityForm: UserEditSecurityForm,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
};
</script>
