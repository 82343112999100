<template>
    <div class="w-full h-full">
        <v-form v-if="user" class="space-y-8" @submit.prevent="submitUserForm(userForm.user_id, userForm)">
            <v-form-group v-if="$route.name !== 'me.security'" :permissions="userForm.user_id != me.user_id ? ['users.update'] : []">
                <v-form-label>User Type</v-form-label>
                <v-form-select v-model="userForm.user_type_id" :options="selectOptions(userTypes, 'name', 'user_type_id')" default-label="User Type" :disabled="isLoading || !$me.hasPermission('users.update') || (userForm.user_id == me.user_id && $route.name == 'me.security')" />
            </v-form-group>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                    <v-form-label>Password</v-form-label>
                    <v-form-input v-model="userForm.password" type="password" name="password" placeholder="Leave blank if you don't want to change the password" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" autocomplete="new-password" />
                </v-form-group>

                <v-form-group :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                    <v-form-label>Password Confirmation</v-form-label>
                    <v-form-input v-model="userForm.password_confirmation" type="password" name="password_confirmation" placeholder="Leave blank if you don't want to change the password" :readonly="!($me.hasPermission('users.update') || userForm.user_id == me.user_id)" :disabled="isLoading" />
                </v-form-group>
            </div>

            <v-form-group v-if="roles && $route.name !== 'me.security'" :permissions="userForm.user_id != me.user_id ? ['users.update'] : []">
                <v-form-label>Roles</v-form-label>

                <v-form-group class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
                    <template v-for="(role, roleIndex) in roles">
                        <v-form-checkbox :key="'role_' + roleIndex" v-model="userForm.roleForm" :value="role.role_id" :disabled="isLoading || !$me.hasPermission('users.update') || (userForm.user_id == me.user_id && $route.name == 'me.security')">
                            <span class="block">
                                {{ role.name }}
                            </span>
                        </v-form-checkbox>
                    </template>
                </v-form-group>
            </v-form-group>

            <v-form-group v-if="$route.name !== 'me.security'" :permissions="userForm.user_id != me.user_id ? ['users.read'] : []">
                <v-form-label>Options</v-form-label>

                <v-form-group class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
                    <v-form-checkbox v-model="userForm.is_approved" :disabled="isLoading || !$me.hasPermission('users.update') || (userForm.user_id == me.user_id)">
                        Is Approved
                    </v-form-checkbox>

                    <v-form-checkbox v-model="userForm.is_login_enabled" :disabled="isLoading || !$me.hasPermission('users.update') || (userForm.user_id == me.user_id)">
                        Login Enabled
                    </v-form-checkbox>
                </v-form-group>
            </v-form-group>

            <v-form-group class="flex w-full justify-end">
                <v-button class="w-full sm:w-auto" color="primary" text-alignment="center" :disabled="isLoading || !($me.hasPermission('users.update') || userForm.user_id == me.user_id)">
                    Save Changes
                </v-button>
            </v-form-group>
        </v-form>
    </div>
</template>

<script>
import RoleService from "@/services/modules/role-service";
import UserService from "@/services/modules/user-service";
import UserTypeService from "@/services/modules/user-type-service";
import Collect from "collect.js";

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            roleService: RoleService,
            roles: {},
            userService: UserService,
            userForm: {
                ...this.user,
                roleForm: null,
            },
            userTypeService: UserTypeService,
            userTypes: [],
            me: null,
        };
    },
    watch: {
        user: {
            handler(value) {
                this.userForm = {
                    ...value,
                    roleForm: null,
                };
                this.parseRoles(value);
            },
            deep: true,
        },
    },
    created() {
        this.me = this.$me.getUser();

        this.userTypeService.index()
            .then((response) => {
                this.userTypes = [
                    ...response.data.data,
                ];
            });

        this.roleService.index()
            .then((response) => {
                this.roles = response.data.data;
            })
            .finally(() => {
                this.parseRoles(this.user);
            });
    },
    methods: {
        parseRoles({ roles }) {
            this.userForm.roleForm = Collect(roles).pluck("role_id").toArray();
        },
        submitUserForm(userId, payload) {
            this.toggleLoading();

            this.userService.updateSecurity(userId, payload)
                .then((response) => {
                    this.$emit("user-updated", response);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
        selectOptions(data, labelField, valueField) {
            if (data) {
                return data.map((item) => ({ label: item[labelField], value: item[valueField] }));
            }
            return null;
        },
    },
};
</script>
